import * as React from 'react'
import {FC, ReactNode, useState} from "react";
import Container from "../layout/Container";
import clsx from "clsx";
import {useScrollPosition} from '@n8tb1t/use-scroll-position'

interface Props {
    children: ReactNode,
    classNames?: string,
}

const StickyHeader: FC<Props> = ({children, classNames}) => {


    return <div className={clsx('md:sticky top-0 z-50', classNames,)}>
        <Container>
            {children}
        </Container>
    </div>
}

export default StickyHeader;