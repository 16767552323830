import * as React from 'react'
import {FC} from 'react'
import BasePage from '../pageParts/BasePage'
import Container from '../components/layout/Container'
import ContactForm from '../pageParts/ContactForm'

interface Props {

}

const Contact: FC<Props> = () => {

    return null;

    return <BasePage>

        <Container>
            <div className={'py-40 max-w-screen-sm'}>
                <h1 className={'font-extrabold md:text-4xl text-3xl leading-tight'}>Kontakt</h1>

                <p className={'mt-8 text-sm'}>
                    Bitte haben Sie Verständnis, dass wie keine Support-Anfragen von Endkunden bearbeiten können.<br /><br/>
                    Sollten Sie <b>Ihren Zählerstand übermitteln</b> wollen, aber keinen Zugang zu unserem Ableseportal haben,
                    dann wenden Sie sich bitte an <b>direkt an den Kundensupport Ihres Versorgers</b>.
                </p>


                <br />

                <ContactForm />
            </div>

        </Container>

    </BasePage>
}


export default Contact