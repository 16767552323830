import * as React from 'react'
import {FC, ReactNode} from "react";


interface Props {
    primary?: string,
    text?: string,
    background?: string,
    children: ReactNode
}

const Theme: FC<Props> = ({
                              children,
                              text,
                              primary,
                              background
                          }) => {

    const style = {
        "--color-primary": primary,
        "--color-background": background,
        "color": text
    } as React.CSSProperties

    return <div style={style}>
        {children}
    </div>
}

export default Theme;