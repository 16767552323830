import * as React from "react"
import { Link } from "gatsby"
import BasePage from '../pageParts/BasePage'
import CenteredHero from '../components/hero/LargeCenteredHero'

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <BasePage>
      <div style={{paddingTop: 80,minHeight: 'calc(100vh - 440px)'}}>
        <CenteredHero title={"Wo bin ich?"}
                      text={"Sorry, aber diese Seite gibt es leider nicht."}
        >
          <p className={'mt-4'}>
            Aber schau dich doch mal auf der <a href={'/'}>Startseite</a> um!
          </p>
        </CenteredHero>
      </div>
    </BasePage>
  )
}

export default NotFoundPage
