/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Organization } from 'schema-dts'
import { helmetJsonLdProp, jsonLdScriptProps } from 'react-schemaorg'

import { useStaticQuery, graphql } from 'gatsby'

function SEO({}) {
    const metaDescription = 'Das Ableseportal für die Ablesung von Zählerständen.'
    const title = 'ableseportal.de - einfach Zähler ablesen'
    const author = 'envago GmbH'

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: 'de',
                }}
                title={title}
                titleTemplate={title}
                meta={[
                    {
                        name: `description`,
                        content: metaDescription,
                    },
                    {
                        name: 'keywords',
                        content: 'Ableseportal, Ablesung, Zähler, Zählerstand, Zählerstandserfassung, Ablesesoftware, Software, Stromzähler, Gaszähler, Wasserzähler, Zählererfassung, Zählerauslesung, Hausverwaltungen, Energieversorger, Wasserversorger',
                    },
                    {
                        name: 'robots',
                        content: 'index,follow',
                    },
                    {
                        property: `og:title`,
                        content: title,
                    },
                    {
                        property: `og:description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: author,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription,
                    },
                ].concat()}


            >
                <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
                <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
                <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
                <link rel='manifest' href='/site.webmanifest' />
                <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
                <meta name='msapplication-TileColor' content='#ffffff' />
                <meta name='theme-color' content='#ffffff' />
                {/*          <script type="application/ld+json">*/}
                {/*              {`*/}
                {/*  {*/}
                {/*    "@context": "https://schema.org",*/}
                {/*    "@type": "Organization",*/}
                {/*    "url": "https://www.ableseportal.de",*/}
                {/*    "name": "ableseportal.de",*/}
                {/*    "parentOrganization" : {*/}
                {/*        "@context": "https://schema.org",*/}
                {/*        "@type": "Organization",*/}
                {/*        "url": "https://www.envago.de",*/}
                {/*        "name": "envago GmbH"*/}
                {/*    },*/}
                {/*    "contactPoint": {*/}
                {/*      "@type": "ContactPoint",*/}
                {/*      "telephone": "+49-341-975-95080",*/}
                {/*      "contactType": "Sales Contact",*/}
                {/*      "email" : "info@ableseportal.de"*/}
                {/*    }*/}
                {/*  }*/}
                {/*`}*/}
                {/*          </script>*/}
            </Helmet>
            <Helmet
                script={[
                    helmetJsonLdProp<Organization>({
                        '@context': 'https://schema.org',
                        '@type': 'Organization',
                        name: 'ableseportal.de',
                        description: 'Das Ableseportal für die Zählerstandserfassung',
                        logo: 'https://static.ableseportal.de/logo/ableseportal_logo_500.png',
                        url: 'https://www.ableseportal.de',
                        contactPoint: {
                            '@type': 'ContactPoint',
                            contactType: 'Sales',
                            email: 'info@ableseportal.de',
                            telephone: '+4934197595080',
                        },
                        parentOrganization: {
                            '@type': 'Organization',
                            name: 'envago GmbH',
                            description: 'Smarte Software für Energieversorger',
                            email: 'info@envago.de',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: 'Forststr. 9',
                                postalCode: '04229',
                                addressLocality: 'Leipzig',
                            },
                        },
                    }),
                ]}
            />
        </>
    )
}


export default SEO
