import * as React from 'react'

import LeftAlignedHero from '../components/hero/LeftAlignedHero'
import Container from '../components/layout/Container'
import Theme from '../components/layout/Theme'
import Feature from '../components/feature'
import Features from '../components/feature/Features'
import CenteredHero from '../components/hero/CenteredHero'
import {StaticImage} from 'gatsby-plugin-image'
import BasePage from '../pageParts/BasePage'
import LargeCenteredHero from '../components/hero/LargeCenteredHero'
import Button from '../components/button/Button'
import SimpleFeature from '../components/feature/SimpleFeature'
import Grid from '../components/layout/Grid'
import PhoneMockVideo from '../components/mock/PhoneMockVideo'
import Icon, {Icons} from '../components/Icons/Icons'
import SEO from '../pageParts/SEO'
import {Carousel} from "../components/carousel/Carousel";


// markup
const IndexPage = () => {

    // useEffect(()=>{
    //     document.documentElement.classList.add("dark");
    // },[])

    return (
        <BasePage>
            <>
                <SEO />
                <Theme>
                    <Container classNameWrapper={'md:my-40 my-12'}>
                        <LeftAlignedHero
                            title={'Digitalisieren Sie Ihre Ablesung'}
                            text={<p>
                                Unsere Lösung digitalisiert Ihre Zähler&shy;stands&shy;erfassung im handumdrehen.
                                Das Ablese&shy;portal informiert Ihre Kunden per E-Mail oder Brief über die anstehende Ablesung.
                                Ihre Kunden melden sich ganz einfach mit dem bereitgestellten QR-Code und Ihrem Smartphone/Tablet
                                oder ganz klassisch mit den individuellen Zugangsdaten am PC an.
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*<div className={'space-x-2 text-lg'}>*/}
                                {/*    <Button primary={true}></Button>*/}
                                {/*</div>*/}
                            </p>}
                        >
                            <div className={'flex max-w-screen'}>
                                <StaticImage
                                    className={'mx-auto max-w-full'}
                                    width={500}
                                    src={'../images/ableseportal-mobil.png'} />
                            </div>
                        </LeftAlignedHero>
                    </Container>
                </Theme>

                <div className={'h-14'}>

                </div>


                <Container>
                    <Carousel/>
                </Container>


                <Theme primary={'#bada55'}>
                    <Container classNameWrapper={'bg-gray-100 dark:bg-gray-800'}>
                        <CenteredHero
                            className={'md:py-40 py-24'}
                            title={'Komfortable Ablesung für Ihre Kunden'}
                        >
                            <div>
                                <p>
                                    Einfach und schnell erledigt.
                                </p>
                                <div className={'mx-auto flex justify-center mt-20'}>
                                    <PhoneMockVideo
                                        className={''}
                                        maxWidth={350} />
                                </div>
                            </div>
                        </CenteredHero>
                    </Container>
                </Theme>

                <Container classNameWrapper={'md:py-40 py-20 bg-blue-100 dark:bg-pink-900'}>
                    <h1 className={'font-extrabold md:text-6xl text-4xl leading-tight font-extrabold text-center md:my-20 my-12'}>
                        Tolle Funktionen</h1>
                    <Grid cols={3}>
                        <SimpleFeature
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.TabletCellphone} />}
                            title={'Sieht überall gut aus'}>
                            Auf dem PC, Tablet und Smartphone. Wenn Sie möchten mit Ihrem Logo und in Ihrem Design.
                        </SimpleFeature>

                        <SimpleFeature
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.NoWifi} />}
                            title={'Kein Internet? Kein Problem!'}>
                            Natürlich hat man nicht vor jedem Zähler eine brauchbare Internetverbindung.
                            Mit dem Ableseportal lesen Sie einfach im Offline-Modus weiter ab und senden später.
                        </SimpleFeature>

                        <SimpleFeature
                            title={'Say Cheeeeeese'}
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.Camera} />}
                        >
                            Mit Fotonachweis. Direkt über das Portal ein Foto aufnehmen.
                            Wenn Sie möchten auch nur bei unplausiblen Zählerständen.
                        </SimpleFeature>

                        <SimpleFeature
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.QRScan} />}
                            title={'Einfachster Login'}>
                            Individuellen QR-Code scannen und direkt angemeldet. So einfach!
                        </SimpleFeature>

                        <SimpleFeature
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.Share} />}
                            title={'Zähler weiterteilen'}>
                            Subzugänge für einzelne Zähler oder ganze Häuser erstellen
                            und z.B. per Mail zur Ablesung weitergeben.
                        </SimpleFeature>

                        <SimpleFeature
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.SwapHorizontal} />}
                            title={'Einfache Datenübernahme'}>
                            Im- und Export Ihrer Daten per Excel oder CSV Datei.
                        </SimpleFeature>

                        <SimpleFeature
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.Lifebuoy} />}
                            title={'Eingebaute Hilfe'}>
                            Wie lese ich ab? Wo ist die Zählernummer? Dynamische Hilfe je nach Zählertyp.
                        </SimpleFeature>



                        {/*<div className={'hidden sm:block'}></div>*/}

                        <SimpleFeature
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.Speech} />}
                            title={'Hinweisfunktion'}>
                            Zähler wurde gewechselt? Verbrauch ist in diesem Jahr erhöht? Einfach einen Hinweis mitgeben.
                        </SimpleFeature>

                        <SimpleFeature
                            icon={<Icon className={'mx-auto w-16 text-primary'} svg={Icons.CheckCircle} />}
                            title={'Klingt Plausibel'}>
                            Ist plausibel! Eingebaute Plausibilisierung der Zählerstände.
                        </SimpleFeature>


                        {/*<SimpleFeature title={'Im- und Export'}>*/}
                        {/*    Einfacher Im- und Export Ihrer Auftragsdaten per Excel bzw. CSV Datei*/}
                        {/*</SimpleFeature>*/}

                        {/*<SimpleFeature title={'Responsive Web-App'}>*/}
                        {/*    Optimal Nutzen auf dem Smartphone, Tablet oder PC*/}
                        {/*</SimpleFeature>*/}

                        {/*<SimpleFeature title={'Sicherer QR-Login'}>*/}
                        {/*    Es geht nicht einfacher: Individuelle QR-Codes für jeden Kunden. Mit dem Smartphone/Tablet*/}
                        {/*    scannen und sofort angemeldet.*/}
                        {/*</SimpleFeature>*/}

                        {/*<SimpleFeature title={'Foto-Nachweis'}>*/}
                        {/*    Stellen Sie sicher, dass die Ablesungen korrekt sind: Zu jeder Ablesung können Ihre Kunden*/}
                        {/*    Nachweisfotos des Zählers hochladen. Einfach & direkt über die Smartphone Kamera.*/}
                        {/*</SimpleFeature>*/}


                        {/*<SimpleFeature title={'Sub-Zugänge erstellen'}>*/}
                        {/*    Ablesungen unkompliziert weiterteilen: Hausverwaltungen teilen ihre Ablesungen z.B. mit*/}
                        {/*    Objektbetreuern; die Objektbetreuer teilen Zugänge mit den Mietern.*/}
                        {/*</SimpleFeature>*/}

                        {/*<SimpleFeature title={'Flexible Hilfe'}>*/}
                        {/*    Wie lese ich den Zähler ab? Welchen Wert muss ich eingeben? Das Ablesportal unterstützt Ihre*/}
                        {/*    Kunden bei der Ablesung: Je nach Zählertyp werden dynamische Hilfe-Texte angezeigt. Einfach*/}
                        {/*    geht es nicht.*/}
                        {/*</SimpleFeature>*/}


                    </Grid>
                </Container>

                <Container classNameWrapper={'md:my-32 my-20'}>
                    <h1 className={'font-extrabold md:text-6xl text-4xl leading-tight font-extrabold text-center md:my-20 my-12'}>
                        Für Sie gemacht
                    </h1>
                    <Features>
                        <Feature
                            className={''}
                            iconWrapperClassName={'bg-gradient-to-br from-indigo-400 to-red-400 '}
                            title={'Hausverwaltungen'}
                            icon={<StaticImage src={'../images/icons/icon_property_management.svg'}
                                               alt={'Icon Hausverwaltungen'} />}
                        >
                            Als Hausverwaltung teilen Sie Ihre Ablesungen mit Objekt&shy;betreuern, Hausmeistern
                            oder direkt mit Ihren Mietern.
                        </Feature>
                        <Feature
                            iconWrapperClassName={'bg-gradient-to-br from-red-400  to-pink-400'}
                            title={'Städte & Gemeinden'}
                            icon={<StaticImage src={'../images/icons/icon_cities.svg'}
                                               alt={'Icon Städte und Gemeinden'} />}
                        >
                            Lesen Sie Ihre Zähler in Objekten wie Schulen, der Verwaltung oder auch Ampelanlagen ab.
                        </Feature>
                        <Feature
                            title={'Energieversorger'}
                            iconWrapperClassName={'bg-gradient-to-br from-red-400 to-yellow-400'}
                            icon={<StaticImage src={'../images/icons/icon_energy_supplier.svg'}
                                               alt={'Icon Energieversorger'} />}
                        >
                            Als Netzbetreiber oder Energie&shy;vertrieb stellen Sie Ihren Kunden eine moderne Lösung zur
                            Selbst&shy;ablesung bereit.
                        </Feature>
                        <Feature
                            iconWrapperClassName={'bg-gradient-to-br to-indigo-400 from-blue-400 '}
                            title={'Wasserversorger'}
                            icon={<StaticImage src={'../images/icons/icon_water_supplier.svg'}
                                               alt={'Icon Wasserversorger'} />}
                        >
                            Kunden oder Außen&shy;dienst&shy;mit&shy;arbeiter von kommunalen Was&shy;ser&shy;versorgern
                            nutzen das Ableseportal
                            für die Erfassung von Zählerständen.
                        </Feature>
                        <Feature
                            iconWrapperClassName={'bg-gradient-to-br from-primary to-green-500'}
                            title={'Ablesedienstleister'}
                            icon={<StaticImage src={'../images/icons/icon_meterreading_provider.svg'}
                                               alt={'Icon Ablesedienstleister'} />}
                        >
                            Als Ablesedienstleister lesen Sie Zählerstände im Auftrag eines
                            Versorgungs&shy;unter&shy;nehmens oder einer Haus&shy;verwaltung ab.
                        </Feature>

                    </Features>
                </Container>

                <Container classNameWrapper={'md:py-40 py-20 bg-blue-100 dark:bg-pink-900'}>
                    <LargeCenteredHero
                        title={'Das Ableseportal wird zu Ihrem Ableseportal'}
                        text={<div>
                            <p className={'mt-4'}>
                                Das Ableseportal passt sich Ihnen an. Wir übernehmen die Farben &amp; Logos Ihres
                                Cooporate Designs für das Ableseportal und die Kommunikation mit Ihren Kunden.
                                <br /><br />Damit das Ableseportal zu Ihrem individuellen Ableseportal wird.
                            </p>
                            <div className={'mt-8'}>
                                <StaticImage
                                    placeholder={'blurred'}
                                    style={{ maxWidth: 600 }}
                                    src={'../images/ableseportal_individuell.png'} alt={'Individuelle Anpassung'} />
                            </div>


                        </div>}
                    >


                    </LargeCenteredHero>


                </Container>


                <Container classNameWrapper={'bg-gray-100 dark:bg-gray-700 md:py-32 py-20'}>
                    <LargeCenteredHero
                        title={'Hybride Kommunikation'}
                        text={<div>
                            <p>
                                Wir kümmern uns um die Kommunikation mit Ihren Kunden.<br />
                                Per E-Mail oder per Brief. Einfach, günstig und individuell.<br />

                            </p>

                            <div className={'mt-8'}>
                                <StaticImage src={'../images/ableseportal_hybrid.png'}
                                             style={{ maxWidth: 1000 }}
                                             alt={'Ableseportal Briefanschreiben'} />
                            </div>

                            <p className={'max-w-xl mx-auto mt-16'}>
                                <small>
                                    Wir versenden E-Mail in Ihrem Namen um die Kunden über die Ablesung zu informieren.
                                    Wenn keine E-Mail-Adressen für einzelne oder alle Kunden vorhanden sind, werden
                                    automatisch Briefe generiert und über die Deutsche Post AG versendet.
                                </small>
                            </p>

                            <p className={'max-w-xl mx-auto mt-16'}>
                                <h3 className={'font-bold m-4'}>Fallback inklusive</h3>
                                <small>
                                    E-Mails erreichen leider nicht immer den Empfänger. Ist eine E-Mail nicht zustellbar
                                    - zum Beispiel weil das Postfach voll ist - wird dieses vom Ableseportal erkannt und
                                    ein Brief an den Kunden ausgelöst.
                                </small>
                            </p>

                        </div>}
                    >

                    </LargeCenteredHero>
                </Container>

                <Container classNameWrapper={'md:py-32 py-20 dark:bg-indigo-900'}>
                    <CenteredHero title={'Ob Groß oder Klein: Es skaliert'}>
                        Jedes Jahr werden über unsere Lösungen über <b>3.000.000 Ablesungen</b> verarbeitet und
                        durchgeführt.<br /><br />
                        Durch die SaaS-Cloud-Lösung können wir unsere Expertise und smarten Lösungen für
                        jede Größe von Ablesekampagnen bereitstellen: Für kleine Hausverwaltungen und lokale Versorger
                        genauso wie für große, überregionale Energieunternehmen.
                    </CenteredHero>
                </Container>


                <Container classNameWrapper={'md:py-40 py-20 bg-indigo-50 dark:bg-pink-900'}>
                    <CenteredHero title={'Hallo, was können wir für Sie tun?'}>
                        <p>
                            Sie haben Interesse an unseren Lösungen? Sie möchten das Ableseportal ausprobieren?<br />
                        </p>
                    </CenteredHero>
                    <div className={'text-center mt-8'}>
                        <p>
                            Gerne senden wir Ihnen einen Zugang zu unserem Demo System und beantworten Ihre Fragen:<br />
                            <br />
                        </p>


                        <div className={'space-x-4'}>
                            {/*<Button*/}
                            {/*    href={'/contact/'}*/}
                            {/*    primary={true}>Kontaktformular</Button><br/>*/}
                            {/*<br/>*/}
                            <Button
                                hiddenHref={'mailto:info!ableseportal@de'}
                                primary={true}>per E-Mail</Button>
                            <Button primary={false}>0341 - 975 950 80</Button>
                        </div>
                        <div className={'max-w-md mx-auto mt-16'}>
                            <small>
                                Bitte haben Sie Verständnis, dass wir telefonisch keine Support Anfragen von Endkunden
                                entgegen nehmen können. Bei Fragen, wenden Sie sich bitte an den Kundendienst von Ihrem Versorger.
                            </small>
                        </div>
                    </div>
                </Container>


                <Theme primary={'#ffffffdd'} background={'#bada55'}>
                    <Container classNameWrapper={'py-20 bg-background dark:text-gray-800'}>
                        <CenteredHero title={'Darf es etwas mehr sein?'} text={
                            <p className={'dark:text-gray-800'}>
                                Fragen Sie uns gerne nach unseren Enterprise Lösungen.<br /><br />
                                Oder besuchen Sie uns auf<br />
                                <a className={'dark:text-gray-800'}
                                   href={'https://www.envago.de'}
                                   title={'envago Webseite'}
                                   target={'_blank'}>www.envago.de</a>
                            </p>
                        }>

                        </CenteredHero>
                    </Container>
                </Theme>
            </>
        </BasePage>
    )
}

export default IndexPage
