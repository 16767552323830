import * as React from 'react'
import { FC, ReactNode } from 'react'
import Page from '../components/layout/Page'
import Header from './Header'
import Footer from '../components/footer/Footer'
import Container from '../components/layout/Container'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from './SEO'
import { Helmet } from 'react-helmet'
import '../css/index.css'

interface Props {
    children: ReactNode
}

const BasePage: FC<Props> = ({ children }) => {
    return <Page>
        <div>


            <Header />
            <main>
                {children}
            </main>
            <Footer>
                <Container classNameWrapper={'bg-primary pb-6 md:pt-24 pt-8 text-sm text-white text-opacity-80'}>
                    <div className={'grid md:grid-cols-3 grid-cols-1'}>
                        <div className={'my-12 md:my-0'}>
                            Das Ableseportal für Zählerablesungen.
                        </div>
                        <div>

                        </div>
                        <div>
                            <StaticImage height={24}
                                         src={'../images/ableseportal_logo_white_80.png'}
                                         alt={'Ableseportal Logo'} /><br />
                            <br />
                            envago GmbH<br />
                            Forststr. 9<br />
                            04229 Leipzig<br />
                            <br />
                            Tel.: <a className={'text-white text-opacity-80'} href={'tel:034197595080'}>0341 - 975 950
                            80</a>
                        </div>
                    </div>
                    <div className={'text-center mt-24 text-xs font-light'}>
                        Copyright © 2021 envago GmbH · Alle Rechte vorbehalten · All rights reserved.<br />
                        <a className={'text-white  '} href={'/impressum/'}>Impressum &amp; Rechtliches</a>
                    </div>
                </Container>
            </Footer>
        </div>

    </Page>
}

export default BasePage