import * as React from 'react'
import { FC, MouseEventHandler, ReactNode, useState } from 'react'
import clsx from 'clsx'

interface Props {
    children: string,
    onClick?: () => (void),
    primary?: boolean,
    href?: string,
    hiddenHref?: string,
    type?: 'link' | 'button',
    disabled?: boolean,
    className?: string,
}

const Button: FC<Props> = ({
                               children,
                               onClick,
                               primary,
                               href,
                               hiddenHref,
                               type = 'button',
                               disabled = false,
                               className,
                           }) => {

    const [link, setLink] = useState(href)

    const updateLink = () => setLink(hiddenHref.replace('@', '.').replace('!', '@'))

    return <a role='button'
              onClick={disabled ? () => {
              } : onClick}
              href={hiddenHref ? link : href}
              onMouseOver={hiddenHref ? updateLink : undefined}
              className={clsx(
                  {
                      'button p-2 px-6 bg-gray-200 rounded-full transition-all hover:shadow-md ': type === 'button',
                      'bg-primary text-white': primary && !disabled,
                      'bg-gray-300 text-gray-400 hover:shadow-none': disabled,

                  }, className)}>
        {children}
    </a>
}

export default Button