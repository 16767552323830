import * as React from 'react'
import {FC, ReactNode, useState} from "react";
import {StaticImage} from "gatsby-plugin-image";
import MenuItem from "../components/header/MenuItem";
import StickyHeader from "../components/header/StickyHeader";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import clsx from "clsx";

interface Props {
    children?: ReactNode
}

const Header: FC<Props> = ({children}) => {

    const [scrolled, setScrolled] = useState(false);

    useScrollPosition(({ prevPos, currPos }) => {
        if(currPos.y < -10){
            setScrolled(true);
        } else {
            setScrolled(false)
        }
    })

    return <StickyHeader classNames={clsx('backdrop-filter backdrop-blur bg-opacity-50',
        {
            'bg-white' : scrolled,
            'dark:bg-gray-800' : scrolled,
        }
        )}>
        <nav className={'h-12 py-3 flex h-12 items-center w-full  text-gray-400'}>
            <a href={'/'}>
                <StaticImage
                    placeholder={'blurred'}
                    fadeIn={false}
                    className={'object-contain'}
                    height={28}
                    src={'../images/ableseportal_logo_80.png'} alt={"Ableseportal.de LOGO"}/>
            </a>
            <div className={'flex-1'}/>
            <menu className={'space-x-8 text-sm hidden md:visible'}>
                <MenuItem>Hallo</MenuItem>
                <MenuItem>Welt</MenuItem>
                <MenuItem href={'/faq'}>FAQ</MenuItem>
            </menu>
        </nav>
    </StickyHeader>
}

export default Header;