import * as React from 'react'
import {FC, ReactNode} from "react";

interface Props {
    children: string
}

const PageTitle: FC<Props> = ({children}) => {
    return <h1 className={'font-extrabold text-4xl leading-tight font-extrabold'}>{children}</h1>
}

export default PageTitle;