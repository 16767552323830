import * as React from 'react'
import {StaticImage} from "gatsby-plugin-image";
import './PhoneMockVideo.css'

import mock from './iphone-se-mock.png';
// import mp4 from '/static/videos/ablesung_zaehler.mp4'
// import webm from '/static/images/videos/ablesung_zaehler.webm'

type Props = {
    maxWidth?: number,
    className?: string | undefined
}

const PhoneMockVideo = ({maxWidth = 400, className = ''} : Props) => {



    return <div className={`phonemock ${className}`} style={{maxWidth: maxWidth}}>
        {/*<StaticImage*/}
        {/*    className={'mx-auto max-w-full'}*/}
        {/*    width={500}*/}
        {/*    src={'../images/iphone-se-mock.png'}/>*/}

        <video className={'video'} loop={true} autoPlay={true} muted={true}>
            <source src={'/videos/ablesung_zaehler.webm'} type={'video/webm'}/>
            <source src={'/videos/ablesung_zaehler.mp4'} type={'video/mp4'}/>
        </video>
        <img className={'mock'} src={mock}/>


    </div>
}

export default PhoneMockVideo;