import * as React from 'react'
import {FC, ReactNode} from "react";


interface Params {
    children: ReactNode
}

const Page : FC<Params> = ({children}) => {
    return <div className={''}>
        {children}
    </div>
}

export default Page;