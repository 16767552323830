import * as React from 'react'
import {FC, ReactNode} from "react";

interface Props {
    children: ReactNode,
    icon?: ReactNode,
    title?: string,
}

const SimpleFeature: FC<Props> = ({children, icon, title}) => {
    return <section className={'text-center'}>
        <div>
            {icon}
        </div>

        <h3 className={'font-bold my-2 text-xl'}>{title}</h3>

        <div className={'leading-loose text-gray-600 dark:text-gray-300 font-light '}>
            {children}
        </div>

    </section>
}

export default SimpleFeature;