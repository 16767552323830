import * as React from 'react'
import {FC, ReactNode} from "react";
import clsx from "clsx";

interface Props {
    children: ReactNode,
    cols: number
}

const Grid: FC<Props> = ({children, cols}) => {
    return <div className={clsx("grid gap-8", {
        'md:grid-cols-3 grid-cols-1': cols === 3,
    })}>
        {children}
    </div>
}

export default Grid;