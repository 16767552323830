import * as React from 'react'
import {FC, ReactNode} from "react";
import clsx from 'clsx'

interface Params {
    children: ReactNode,
    classNameWrapper?: string,
}

const Container: FC<Params> = ({children, classNameWrapper}) => {
    return <div className={clsx(classNameWrapper)}>
        <div className={clsx('container max-w-screen-xl mx-auto px-4')}>
            {children}
        </div>
    </div>
}

export default Container
