import * as React from 'react'
import { Helmet } from 'react-helmet'


export const NoSEO = () => {
    return <Helmet


        meta={[{
            name: 'robots',
            content: 'noindex',
        }]}
    >
    </Helmet>
}

export default NoSEO