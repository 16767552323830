import * as React from 'react'
import {FC, ReactNode} from "react";
import Container from "../layout/Container";
import {StaticImage} from "gatsby-plugin-image"

interface Params {
    children: ReactNode,
    title: string,
    text: ReactNode | string,

}

const LeftAlignedHero: FC<Params> = ({children, title, text}) => {
    return <section className={'flex space-x-4 flex-col md:flex-row'}>
        <div className={'md:w-3/5 lg:w-2/5'}>
            <h1 className={'font-extrabold md:text-5xl lg:text-6xl text-4xl leading-tight font-extrabold md:mb-12 mb-6'}>{title}</h1>
            <p className={'md:text-2xl sm:text-xl text-md font-light dark:text-gray-300 md:leading-relaxed'}>
                {text}
            </p>
        </div>
        <div className={'md:w-2/5 lg:w-3/5 mt-8'}>
            {children}
        </div>
    </section>

}


export default LeftAlignedHero;