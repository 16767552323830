import * as React from 'react'
import {FC, ReactNode} from "react";
import Card from "../card";
import clsx from "clsx";
import {StaticImage} from "gatsby-plugin-image";


interface Props {
    children?: ReactNode,
    className?: string,
    title?: string,
    iconWrapperClassName?: string,
    icon?: ReactNode
}

const Feature: FC<Props> = ({
                                children,
                                className,
                                title,
                                iconWrapperClassName,
                                icon,
                            }) => {

    return <div className={clsx('flex flex-col px-4 py-4 items-center', className)}>
        <div className={clsx('place-self-center w-28 h-28 rounded-full flex align-middle justify-center p-5',
            iconWrapperClassName
        )}>
            {icon}
        </div>
        <h2 className={'font-bold my-2 mt-6 text-xl'}>
            {title}
        </h2>
        <p className={'text-center leading-loose text-gray-600 dark:text-gray-300 font-light '}>
            {children}
        </p>
    </div>
}

export default Feature;