import * as React from 'react'
import {FC, ReactNode} from "react";

interface Props {
    children: ReactNode
}

const Features: FC<Props> = ({children}) => {
    return <div className={'grid xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 -mx-4 divide-x-0 xl:divide-x sm:divide-y-0 dark:divide-gray-800'}>
        {children}
    </div>
}

export default Features;