import React, {useRef, useState} from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import {Pagination, Navigation, Controller, Autoplay} from "swiper";
import {StaticImage} from "gatsby-plugin-image";


export const Carousel = () => {
    return (
        <>
            <h1 className={'font-extrabold md:text-6xl text-4xl leading-tight font-extrabold text-center md:my-20 my-12'}>
                Das Ableseportal
            </h1>
            <Swiper
                navigation={true}
                pagination={true}
                autoplay={{
                    delay: 6000,
                    disableOnInteraction: true,
                }}
                centeredSlides={true}
                modules={[Pagination, Navigation, Autoplay]} className="">
                <SwiperSlide>
                    <div className={'text-center'}>

                        <p className={'mb-4 leading-relaxed md:leading-relaxed md:text-2xl text-lg font-light dark:text-gray-300'}>
                            Willkommen in Ihrem Ableseportal
                        </p>
                        <StaticImage src={'../../images/screenshots/web_screenshot_portal.png'} alt={'Ableseportal: Startseite'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={'text-center'}>

                        <p className={'mb-4 leading-relaxed md:leading-relaxed md:text-2xl text-lg font-light dark:text-gray-300'}>
                            Übersichtliche Darstellung der Zähler &amp; Ablesungen
                        </p>
                        <StaticImage src={'../../images/screenshots/web_screenshot_portal_ablesungsuebersicht.png'} alt={'Ableseportal: Zählerübersicht'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={'text-center'}>

                        <p className={'mb-4 leading-relaxed md:leading-relaxed md:text-2xl text-lg font-light dark:text-gray-300'}>
                            Intuitive Bedienung, einfach Ablesen - für alle Zählerarten
                        </p>
                        <StaticImage src={'../../images/screenshots/web_screenshot_portal_zaehler.png'} alt={'Ableseportal: Zählerdetails'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={'text-center'}>

                        <p className={'mb-4 leading-relaxed md:leading-relaxed md:text-2xl text-lg font-light dark:text-gray-300'}>
                            Mit eingebauter Plausibilisierung und Fotonachweisen
                        </p>
                        <StaticImage src={'../../images/screenshots/web_screenshot_portal_zahler_ablesung.png'} alt={'Ableseportal: Ablesung mit Fotos'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={'text-center'}>

                        <p className={'mb-4 leading-relaxed md:leading-relaxed md:text-2xl text-lg font-light dark:text-gray-300'}>
                            Einfach erledigt, jederzeit korrigierbar
                        </p>
                        <StaticImage src={'../../images/screenshots/web_screenshot_portal_zaehler_ablesungerledigt.png'} alt={'Ableseportal: Ablesung erledigt'}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={'text-center'}>

                        <p className={'mb-4 leading-relaxed md:leading-relaxed md:text-2xl text-lg font-light dark:text-gray-300'}>
                            Die dynamische Hilfe passt sich genau an Ihren Zähler an
                        </p>
                        <StaticImage src={'../../images/screenshots/web_screenshot_portal_zaehler_hilfe.png'} alt={'Ableseportal: Ablesung erledigt'}/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

