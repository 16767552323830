import * as React from 'react'
import { FC, ReactNode } from 'react'
import BasePage from '../pageParts/BasePage'
import Container from '../components/layout/Container'
import PageTitle from '../components/layout/PageTitle'
import Button from '../components/button/Button'
import NoSEO from '../pageParts/NoSEO'

interface Props {

}

const impressum: FC<Props> = () => {
    return <BasePage>
        <NoSEO/>
        <Container classNameWrapper={'pt-40'}>
            <PageTitle>Impressum</PageTitle>

            <div className={'my-20'}>
                envago GmbH<br />
                Forststr. 9<br />
                04229 Leipzig<br />
                <br />
                Vertretungsberechtigter Geschäftsführer: Robert Kunkel<br />
                <br />
                Telefon: +49 341 975 9 50 80<br />
                Fax: +49 341 698 72 5 13<br />
                E-Mail: <Button type={'link'} hiddenHref={'mailto:info!ableseportal@de'}>E-Mail</Button><br />
                <br />
                Amtsgericht Leipzig<br />
                Handelsregistereintrag: HRB: 31803<br />
                <br />
                Umsatzsteuer Identifikationssnummer gem. §27a UStG: DE302254050<br />
            </div>
        </Container>

        <Container>
            <PageTitle>Rechtliches</PageTitle>

            <div className={'my-20 max-w-lg text-jusfify '}>
                <h2 className={'font-bold font-xl my-8'}>Haftung & Nutzung</h2>
                Leider können wir keinerlei Gewähr übernehmen, dass die auf dieser Webseite bereitgestellten
                Informationen aktuell, richtig und vollständig sind. Haftungsansprüche, welche sich auf materielle oder
                ideelle Schäden beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
                durch die Nutzung fehlerhafter und/oder nicht vollständiger Informationen verursacht wurden, sind
                grundsätzliche ausgeschlossen, sofern seitens des Anbieters kein nachweislich vorsätzlich oder grob
                fahrlässiges Verschulden vorliegt.<br />
                <br />
                Alle Angebote sind freibleibend und unverbindlich. Wir behalten uns vor, Teile der Seite oder das
                gesamte Angebot ohne Ankündigung zu verändern, löschen, ergänzen oder die Veröffentlichung einzustellen.<br />
                <br />
                Wir widersprechen an dieser Stelle der Nutzung unserer Kontaktdaten, um uns damit nicht verlangte
                Werbung/Informationsmaterial/Spam-Mails zukommen zu lassen. Sollte dies dennoch geschehen, behalten wir
                uns rechtliche Schritte vor.<br />
                <br />
                <h2 className={'font-bold font-xl my-8'}>Warum gibt es keinen Cookie-Banner?</h2>
                Wir setzen keine Cookies ein oder nur Cookies, die für die technische Bereitstellung der Webseite
                zwingend notwendig sind. Wir nutzen <b>keine Cookies</b> um das Nutzerverhalten auf unserer Webseite zu
                tracken. <br />
                <br />
                Ein "Cookie-Banner" ist nur notwendig, wenn Cookies gesetzt werden, die der Zustimmung des Nutzers
                bedürfen. Da Niemand Bock auf Cookie-Banner hat - wir auch nicht - setzen wir einfach keine Cookies ein.
                Also auch keinen Cookie-Banner.
                <br />

                <h2 className={'font-bold font-xl my-8'}>Datenschutz</h2>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                dieser Datenschutzerklärung.<br />
                <br />
                Die verantworliche Stelle für die Datenverarbeitung auf dieser Webseite ist die envago GmbH. Bitte
                nutzen Sie zur Kontaktaufnahme die im Impressum angegebenen Kontaktmöglichkeiten.<br />
                <br />
                Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
                unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben
                werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br />
                <br />
                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                nicht möglich.<br />
                <br />
                Durch den Besuch der Webseite des Anbieters können Informationen über den Zugriff (Datum, Uhrzeit,
                betrachtete Seite) gespeichert werden. Diese Daten gehören nicht zu den personenbezogenen Daten, sondern
                sind anonymisiert. Sie werden ausschließlich zu statistischen Zwecken ausgewertet. Eine Weitergabe an
                Dritte zu kommerziellen und nichtkommerziellen Zwecken findet nicht statt. Ferner weisen wir Sie darauf
                hin, dass die Datenübertragung übers Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken
                aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt werden kann.<br />
                <br />
                Sie haben das Recht auf Datenübertragbarkeit. Soweit technisch möglich stellen wir Ihnen Ihre Daten in
                einem gängigen, maschinenlesbaren Format zur Verfügung. Diese Daten dürfen Sie an einen anderen
                Verantwortlichen übertragen.<br />
                Sie können eine erteilte Einwilligung zur Datenverarbeitung jederzeit formlos per E-Mail
                widerrufen.<br />
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
                Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie
                zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
                angegebenen Adresse an uns wenden.<br />
                <br />
                Wir erheben und speichern automatisch Informationen während Ihres Besuchs unserer Webseite in so
                genannten Server-Log-Dateien. Hierbei übermittelt Ihr Browser automatisch die folgenden Daten:
                Browsertyp und Browserversion, verwendetes Betriebssystem, Referrer URL, Hostname des zugreifenden
                Rechners, Uhrzeit der Serveranfrage und Ihre IP-Adresse. Die Zusammenführung dieser Daten mit anderen
                Datenquellen findet nicht statt. Diese Log-Dateien werden in kurzen Abständen automatisch
                gelöscht.<br />

                <h3 className={'font-bold font-xl my-4'}>Kontaktformular</h3>
                Bei der Nutzung unseres Kontaktformulars werden Daten per E-Mail an den Betreiber der Webseite sowie an
                die von Ihnen eingetragene E-Mail Adresse übertragen und gespeichert.<br />
                <br />
                Bitte nutzen Sie das Formular <b>nicht</b> um persönliche / sensible Informationen zu übermitteln. Neben
                den von Ihnen eingegebenen Daten wird Ihre IP Adresse übermittelt. Um Spam und Missbrauch zu vermeiden
                setzen wir reCAPTCHA ein (siehe Abschnitt "Google reCAPTCHA"). Wenn Sie damit nicht einverstanden sind,
                dann
                nutzen Sie bitte direkt die im Impressum angegebene E-Mail Adresse, um mit uns in Kontakt zu treten.

                <h3 className={'font-bold font-xl my-4'}>Google reCAPTCHA</h3>

                Wir nutzen "Google reCaptcha" (im Folgenden "reCaptcha"), um sicherzustellen, dass unser Kontaktformular
                nicht von Spammern und Bots missbraucht wird. Dieses wird von Google Inc., 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA, (im Folgenden "Google") angeboten.<br />
                <br />
                reCaptcha prüft und übermittelt hierbei Daten vom Browser des Nutzers und führt hierbei eine Bewertung
                durch. Informationen sowie die Datenschutzerklärung von Google finden Sie unter <a
                target={'_blank'} href={'https://policies.google.com/privacy?hl=de-DE'}>Google Privacy</a>.<br />
                <br />
                Wir binden reCaptcha erst in dem Moment auf die Webseite ein, in dem Sie diesen Datenschutzbedingungen
                in unserem Kontaktformular explizit zustimmen. <br />
                <br />
                Die Verarbeitung erfolgt auf der Grundlage von Artikel 6 Abs. 1 lit. f der DSGVO: Wir haben ein
                berechtigtes Interesse daran, unsere Webseite vor missbräuchlicher Nutzung und SPAM zu schützen.


            </div>
        </Container>
    </BasePage>
}

export default impressum