import * as React from 'react'
import {FC, ReactNode} from "react";

interface Props {
    children: ReactNode,
    anchor?: string,
    href?: string,
}

const MenuItem: FC<Props> = ({children, anchor, href}) => {
    return <a href={href || anchor && `#${anchor}`} className={''}>
        {children}
    </a>
}

export default MenuItem;