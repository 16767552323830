import * as React from 'react'
import {FC, ReactNode} from "react";

interface Props {
    children: ReactNode
}

const Footer: FC<Props> = ({children}) => {
    return <footer>
        {children}
    </footer>
}

export default Footer;