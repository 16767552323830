import * as React from 'react'
import {FC, ReactNode} from "react";
import clsx from "clsx";

interface Props {
    children?: ReactNode,
    title?: string,
    text?: string | ReactNode,
    className?: string,
}

const CenteredHero: FC<Props> = ({
                                     children,
                                     title,
                                     text,
                                     className
                                 }) => {
    return <article className={clsx('mx-auto text-center md:w-2/3', className)}>
        <h1 className={'font-extrabold md:text-6xl text-4xl leading-tight font-extrabold'}>{title}</h1>

        <div className={'mx-auto mt-8 leading-relaxed md:text-2xl text-xl font-light dark:text-gray-300'}>
            {text}
        </div>

        <div>
            {children}
        </div>

    </article>
}

export default CenteredHero;