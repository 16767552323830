import * as React from 'react'
import {FC, ReactNode} from "react";
import clsx from "clsx";

interface Props {
    children?: ReactNode,
    title?: string,
    text?: string | ReactNode,
    className?: string,
}

const CenteredHero: FC<Props> = ({
                                     children,
                                     title,
                                     text,
                                     className
                                 }) => {
    return <article className={clsx('mx-auto text-center md:w-2/3', className)}>
        <h1 className={'font-extrabold md:text-4xl text-3xl leading-tight'}>{title}</h1>

        <p className={'mx-auto mt-8 leading-relaxed md:leading-relaxed md:text-2xl text-lg font-light dark:text-gray-300'}>
            {text || children}
        </p>

    </article>
}

export default CenteredHero;